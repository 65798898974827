import "./ScrollDown.css";

function ScrollDown() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="down-arrow">
        <g id="lower">
          <rect
            id="Rectangle 16"
            x="80.8618"
            y="45"
            width="8"
            height="45"
            rx="4"
            transform="rotate(55 80.8618 45)"
            fill="#D9D9D9"
          />
          <rect
            id="Rectangle 20"
            x="13"
            y="51.5532"
            width="8"
            height="45"
            rx="4"
            transform="rotate(-55 13 51.5532)"
            fill="#D9D9D9"
          />
        </g>
        <g id="upper">
          <rect
            id="Rectangle 18"
            x="80.8618"
            y="18"
            width="8"
            height="45"
            rx="4"
            transform="rotate(55 80.8618 18)"
            fill="#D9D9D9"
          />
          <rect
            id="Rectangle 19"
            x="13"
            y="24.5532"
            width="8"
            height="45"
            rx="4"
            transform="rotate(-55 13 24.5532)"
            fill="#D9D9D9"
          />
        </g>
      </g>
    </svg>
  );
}

export default ScrollDown;
