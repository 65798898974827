import { dotPulse } from "ldrs";
import { motion } from "framer-motion";

dotPulse.register();

export const fadeIn = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.5 },
};

export const fadeInMany = {
  hidden: { opacity: 0 },
  visible: (i) => ({
    opacity: 1,
    transition: {
      delay: i * 0.1 + 0.25,
    },
  }),
};

export const fadeInDown = {
  initial: { opacity: 0, y: -20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
  transition: { duration: 0.5 },
};

export const variants = {
  hidden: {
    opacity: 0,
    height: 0,
    transition: {
      when: "afterChildren",
      duration: 0.2,
    },
  },
  visible: {
    opacity: 1,
    height: "auto",
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.05,
      duration: 0.2,
    },
  },
};

export const itemVariants = {
  hidden: { opacity: 0, y: -10 },
  visible: { opacity: 1, y: 0 },
};

export const loadingAnimation = () => {
  return (
    <motion.div className="animation-container" {...fadeIn}>
      <l-dot-pulse size="62" speed="1.6" color="#d3963add"></l-dot-pulse>
    </motion.div>
  );
};
