import { calendarSvg, peopleSvg, studentSvg } from "svg";

export const persondeals = [
    {
        title: "Senior Citizens 65+",
        text: "10% off any dine-in entree. Please mention when ordering.",
        svg: peopleSvg,
    },
    {
        title: "NU Students",
        text: "10% off any dine-in entree with valid student ID. Please mention when ordering.",
        svg: studentSvg,
    },
]

export const calendarDeals =  {
        title: "Monday's",
        text: "Join us after 5pm for 50% any bottle of wine with purchase of a meal.",
        title2: "Tuesday's",
        text2: "Enjoy any of our delicious Build-your-own-pizzas 50% when you dine in after 5pm.",
        svg: calendarSvg,
}